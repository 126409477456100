$local-shadow: 10px 6px 8px 4px rgba(224, 224, 224, 0.26);
$local-border-radius: 10px;

.FolderChronology-subdomain {
  border: 2px solid $color-alpha;
  padding: 0.7rem 1.3rem;
  border-radius: 20px;
  font-weight: bold;
  color: $color-alpha;
  font-size: 1.5rem;
  width: calc(50% - 70px);
  margin-left: 60px;
}

.FolderChronologyForm {
  max-width: 700px;
  margin: auto;

  h2 {
    color: $color-alpha;
    text-align: center;
    margin-bottom: 3rem;
  }
}

.FolderChronologyForm-select {
  margin-bottom: 3rem;
}

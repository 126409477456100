@import './_knacss/knacss.scss';

// Layout variables
$lay-admin-menu-width: 23rem;

$shadow: 1px 0 5px 2px hsl(0deg 0% 89% / 57%);

// Border radius
$borderRadius: 50px;

$bgMain: rgb(235, 235, 235);

body {
  --lay-header-height: 9rem;
}

@include respond-to('small') {
  body {
    --lay-header-height: 6rem;
  }
}

@import '../../node_modules/swiper/swiper.scss';
@import '../../node_modules/swiper/components/scrollbar/scrollbar.scss';

@import './common/page';
@import './common/input';
@import './common/barlist';
@import './common/utils';
@import './common/form';

@import './components/common/mui/QMuiButton';
@import './components/common/mui/QMuiDataGrid';
@import './components/common/mui/QMuiTextarea';
@import './components/common/mui/QMuiModal';
@import './components/common/mui/QMuiSwitch';
@import './components/common/mui/QMuiTimePicker';
@import './components/common/mui/MuiMenu';
@import './components/common/QSelect';
@import './components/common/QMsg';
@import './components/common/QFile';
@import './components/common/QColorPicker';
@import './components/common/QTag';
@import './components/common/QTable';

@import './components/Header';
@import './components/HeaderProfileMenu';
@import './components/Menu';
@import './components/AdminMenu';
@import './components/MainMenu';
@import './components/ChronoTimeline';
@import './components/ChronologyEdit';
@import './components/SchemaItem';
@import './components/SimplyEditor';
@import './components/DomainManager';
@import './components/NoteCategoryInput';
@import './components/NoteItem';
@import './components/Breadcrumb';
@import './components/InfiniteScroll';
@import './components/Chat';
@import './components/Discussion';
@import './components/FormStep';
@import './components/FolderNote';
@import './components/QSnackbar';
@import './components/DocumentTags';
@import './components/DocumentGestion';
@import './components/Downloader';

@import './pages/Login';
@import './pages/FirstLogin';
@import './pages/CheckFolder';
@import './pages/Admin';
@import './pages/parameters/Parameters';
@import './pages/folder/Folder';
@import './pages/folder/FolderChronology';
@import './pages/folder/QualyTeam';
@import './pages/folder/Synthesis';
@import './pages/folder/Documents';
@import './pages/folder/LawyerCollaboration';
@import './pages/folder/CustomerService';
@import './pages/expert/ExpertHome';
@import './pages/customer/CustomerHome';

.FirstLogin {
  display: flex;
  height: 100vh;
}

.FirstLogin-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 1 55%;
  width: 55%;
  background-color: #0880aa;
  position: relative;
  padding: 0 4rem;
}

.FirstLogin-leftContainer {
  max-width: 650px;
  margin: auto;
  position: relative;
  z-index: 7;
}

.FirstLogin-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 55rem;
  max-width: 100%;

  .Login-title,
  .Login-subtitle {
    color: $color-alpha;
  }
}

.FirstLogin-rightContainer {
  padding: 2rem;
  max-width: 100%;
}

@media screen and (max-width: 1000px) {
  .FirstLogin-left {
    display: none;
  }

  .FirstLogin-right {
    min-width: auto;
  }

  .FirstLogin .Login-header {
    background: $color-alpha;
  }
}

$color-btn-alpha: linear-gradient(to right, #0378a0, #0a90be);
$color-btn-alpha-dark: linear-gradient(
  to right,
  darken(#0378a0, 2),
  darken(#0a90be, 2)
);
$color-btn-gamma: linear-gradient(to right, #b11206, #f44336);
$color-btn-gamma-dark: linear-gradient(
  to right,
  darken(#b11206, 2),
  darken(#f44336, 2)
);

.QMuiButton {
  border-radius: $borderRadius;
  padding: 1.1rem 2.5rem;
  font-weight: 600;
  text-transform: none;
  font-size: 1.4rem;
  line-height: 1.4rem;
  text-align: center;

  &.isOutlined {
    border-width: 2px !important;
  }

  &.isPrimary {
    background: $color-btn-alpha;
    border-color: $color-alpha;

    &:hover {
      background: $color-btn-alpha-dark;
    }

    &.isOutlined {
      background: none;
      color: $color-alpha;

      &:hover {
        background-color: lighten($color-alpha, 5);
        color: white;
      }
    }
  }

  &.isSecondary {
    background-color: $color-beta;
    border-color: $color-beta;

    &:hover {
      background-color: darken($color-beta, 5);
    }

    &.isOutlined {
      background: none;
      color: $color-beta;

      &:hover {
        background-color: lighten($color-beta, 5);
        color: white;
      }
    }
  }

  &.isDanger {
    background: $color-btn-gamma;

    &:hover {
      background: $color-btn-gamma-dark;
    }
  }

  &.isWhite {
    background-color: white;
    border: none;
    color: $color-alpha;

    &:hover {
      color: darken($color-alpha, 5);
      background-color: darken(white, 5);
      border: none;
    }
  }

  &.isGray {
    background-color: $color-gray-2;
    border: none;
    color: $color-alpha;

    &:hover {
      color: $color-alpha;
      background-color: darken($color-gray-2, 5);
      border: none;
    }
  }

  &.isIconDanger {
    border: none;
    color: $color-gamma;
    background-color: transparent;
    box-shadow: none;

    &:hover {
      background-color: transparent;
      box-shadow: none;
      color: darken($color-gamma, 5);
      border: none;
    }
  }

  &.isTransparent {
    border: none;
    color: $color-alpha;
    background-color: transparent;
    box-shadow: none;
  }

  &.isLarge {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }

  &.isSmall {
    padding: 0.9rem 2rem;
    font-size: 1.2rem;
    line-height: 1.2rem;
  }

  &.isDisabled {
    background: none;
  }

  &.isIcon {
    background: none;
    padding: 0.5rem;
    margin: 0.5rem;
    min-width: 0;
    color: $color-alpha;

    svg {
      color: $color-alpha;
    }

    img {
      height: 2.1rem;
    }

    &:hover {
      background: none;
    }

    &:not(:hover) {
      box-shadow: none;
    }

    &.isOutlined {
      border: 2px solid $color-alpha;
      padding: 0.2rem;
    }
    &.isPrimary {
      &.isIcon {
        &.isOutlined {
          border-color: $color-alpha;
          svg {
            color: $color-alpha;
          }
          &:hover {
            background-color: $color-alpha;
            svg {
              fill: white;
            }
          }
        }
      }
    }
    &.isSecondary {
      &.isIcon {
        &.isOutlined {
          border-color: $color-beta;
          svg {
            color: $color-beta;
          }
          &:hover {
            background-color: $color-beta;
            svg {
              fill: white;
            }
          }
        }
      }
    }
    &.isDanger {
      &.isIcon {
        &.isOutlined {
          border-color: $color-gamma;
          svg {
            color: $color-gamma;
          }
          &:hover {
            background-color: $color-gamma;
            svg {
              fill: white;
            }
          }
        }
      }
    }
  }
}

.MuiSvgIcon-root {
  font-size: 2.5rem;
}

.MuiButton-endIcon {
  height: 1.4rem;
  margin-top: -7px;
}

.HeaderProfileMenu {
  position: relative;
  z-index: 10;
  height: 100%;
  display: flex;
  align-items: center;

  .avatar {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 6rem;
    width: 6rem;
    border-radius: 3rem;
  }
}

.HeaderProfileMenu-toggleMenuBtn {
  display: block;
  border: 0;
  padding: 0;
  background: none;
  margin-right: 1rem;
  border-radius: 50%;
  height: 100%;
  cursor: pointer;

  & > img {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    object-fit: cover;
  }

  & > svg {
    height: 6rem;
    width: 6rem;
    fill: white;
  }
}

.HeaderProfileMenu-appearDown {
  position: fixed;
  z-index: 2000;
  top: calc(1.5rem + var(--lay-header-height));
  right: 1.5rem;
}

.HeaderProfileMenu-menu {
  border-radius: 1.5rem;
  padding: 1.2rem 1.8rem;
  box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.2);
  width: 35rem;
  max-width: calc(100vw - 5rem);
  position: relative;
  background: white;

  &::before {
    content: '';
    position: absolute;
    top: -1.9rem;
    right: 2rem;
    width: 0;
    height: 0;
    border-left: 2.5rem solid transparent;
    border-right: 1.2rem solid transparent;
    border-bottom: 2rem solid white;
  }

  li {
    border: 0;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .Menu-item {
    position: relative;
    height: 4rem;
    border: 1px solid $color-alpha;
    border-radius: 2rem;
    background: $color-alpha-light;

    &.isActive {
      border-color: $color-beta;
      &:hover {
        border-left-color: $color-beta;
      }
      .Menu-link {
        color: $color-alpha;
      }
    }

    .avatar {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 30px;
      width: 30px;
      border-radius: 15px;
    }

    svg {
      color: $color-beta;
      font-size: 2.2rem;
    }

    &:hover {
      border-left-color: $color-alpha;

      .Menu-link {
        color: $color-alpha;
      }
    }
  }

  .Menu-link {
    color: white;
    justify-content: center;
    &.active {
      border-left: none;
    }
  }

  .Menu-itemIcon {
    position: absolute;
    left: 0.5rem;
    border-radius: 50%;
    overflow: hidden;
    background: white;
  }
}

.HeaderProfileMenu-user {
  color: $color-alpha;
  margin-bottom: 1rem;

  span {
    display: block;

    &:first-child {
      font-weight: bold;
    }
  }
}

@include respond-to('small') {
  .HeaderProfileMenu {
    .avatar {
      width: 4rem;
      height: 4rem;
    }
  }
}

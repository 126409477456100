.Folder {
  height: calc(100vh - 15rem);
  display: flex;
  flex-direction: column;
}
.Folder-nav {
  position: relative;
  margin-bottom: 2rem;
  z-index: 1000;
}

.Folder-navContainer {
  overflow-x: hidden;
  padding: 0.5rem 0 0.5rem 1rem;

  &.isScrollable {
    overflow-x: auto;
  }

  & > ul {
    display: flex;
    justify-content: space-around;
    width: 97rem;
    list-style: none;
    padding: 0;
    margin: 1rem auto;

    & > li {
      margin-right: 0.25rem;
      margin-left: 0.25rem;
      min-width: 15rem;
      width: 15rem;

      & :last-child {
        margin-right: 1rem;
      }

      .QMuiButton {
        width: 100%;
        height: 100%;
        border-radius: 1.1rem;
        border: 2px solid #fff;
        box-shadow: 0px 0px 10px -1px rgba(140, 140, 140, 0.57);
        white-space: nowrap;
      }
    }
  }
}

.Folder-navGo {
  padding: 0;
  position: absolute;
  top: 0;
  cursor: pointer;
  width: 2.5rem;
  height: 100%;
  overflow: hidden;
  display: none;

  &.isVisible {
    display: block;
  }

  &.toRight {
    right: -2.5rem;

    .MuiSvgIcon-root {
      margin-left: -1.3rem;
    }
  }

  &.toLeft {
    left: -2.5rem;

    .MuiSvgIcon-root {
      margin-left: -1.3rem;
    }
  }

  .MuiSvgIcon-root {
    font-size: 5rem;
    width: 5rem;
    height: 5rem;
    max-width: 5rem;
    max-height: 5rem;
    color: $color-alpha;
  }
}

.Folder-noChronology {
  font-size: 1.8rem;
  text-align: center;
  margin-top: 5rem;
}

.Folder-content {
  margin: 0 2rem;
  flex-grow: 1;
  display: block;
}

.Folder-chronology {
  height: 100%;
  display: flex;
  flex-direction: column;

  .ChronologyEdit-container {
    flex-grow: 1;
  }
}

@include respond-to('small') {
  .Folder.page-container {
    margin-top: 11rem;
    height: calc(100vh - 20rem);
  }

  .Folder-content {
    margin: 0;
  }

  .Folder-chronology {
    height: 100%;
    display: block;

    .ChronologyEdit-container {
      padding: 0;
      padding-bottom: 8rem;
    }
  }
  .ChronologyEdit-right {
    margin: 0;
  }

  .Folder-navContainer {
    & > ul {
      margin-top: 1.5rem;
      width: 108rem;
    }
  }

  .Folder-description {
    display: none;
  }

  .Folder-nav {
    position: fixed;
    top: calc(var(--lay-header-height) - 2.5rem);
    left: 0;
    right: 0;

    .QMuiButton {
      background: white;
      color: $color-alpha;
      padding: 1.1rem 1rem;

      &:hover {
        background: white;
      }

      &.isSecondary {
        color: $color-beta;
      }
    }
  }
}

@include respond-to('small-up') {
  .Folder-navContainer {
    & > ul {
      width: 108rem;
    }
  }
}

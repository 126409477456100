@use 'sass:math';

$avatar-size: 6rem;
.chat {
  background-color: $color-gray-1;
  border-radius: $radius-large;
  padding: map-get($spacers, '6');
  margin-bottom: map-get($spacers, '6');
  box-shadow: 1px 0 5px 2px rgb(227 227 227 / 57%);
  width: 48%;
  &-discussion {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0;
    box-shadow: none;
    .chat-wrapper {
      border-radius: 0;
      padding: 0;
      box-shadow: none;
    }
  }
  &-title {
    color: $color-alpha;
  }
  &-wrapper {
    background-color: $white;
    border-radius: $radius-large;
    padding: map-get($spacers, '6');
    box-shadow: 1px 0 5px 2px rgb(227 227 227 / 57%);
    position: relative;
    height: 100%;
  }
  &-inner {
    margin-bottom: map-get($spacers, '6');
    position: relative;
    height: calc(100% - 8rem);
  }
  &-message {
    position: relative;
    border-radius: $radius-medium;
    color: $white;
    padding: map-get($spacers, '5') map-get($spacers, '5')
      map-get($spacers, '5') map-get($spacers, '7');
    margin: 0 map-get($spacers, '3') 0 map-get($spacers, '6');
    svg,
    .chat-avatar {
      position: absolute;
      left: calc(-#{map-get($spacers, '6')});
      top: map-get($spacers, '4');
      width: $avatar-size;
      height: $avatar-size;
      min-width: $avatar-size;
      min-height: $avatar-size;
      border-radius: math.div($avatar-size, 2);
      background-color: $white;
    }
    .chat-avatar {
      box-shadow: $shadow;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &-message-head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2rem;

    .text-smaller {
      white-space: nowrap;
    }
  }
  &-body {
    min-height: 3rem;
  }
  &-folder {
    .chat-message {
      background-color: $color-alpha;
      svg,
      .chat-avatar {
        color: $color-beta;
      }
    }
    .chat-loading {
      svg {
        color: $color-alpha;
      }
    }
  }
  &-folder,
  &-discussion,
  &-collaboration,
  &-customer {
    .chat-message {
      background-color: $color-beta;
      svg,
      .chat-avatar {
        color: $color-alpha;
      }
    }
    .chat-loading {
      svg {
        color: $color-beta;
      }
    }
  }
  &-folder,
  &-discussion,
  &-collaboration,
  &-expert,
  &-customer {
    .chat-message-me {
      padding: map-get($spacers, '5') map-get($spacers, '7')
        map-get($spacers, '5') map-get($spacers, '5');
      margin: 0 map-get($spacers, '6') 0 map-get($spacers, '3');
      background-color: $color-gray-1;
      color: $color-alpha;
      svg,
      .chat-avatar {
        left: auto;
        right: calc(-#{map-get($spacers, '6')});
      }
    }
  }
  &-folder,
  &-expert,
  &-customer,
  &-collaboration {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    .chat-inner {
      height: calc(100% - 60px);
      flex-grow: 1;
      margin-bottom: 2rem;
    }
  }
  &-form {
    form {
      background-color: $color-gray-1;
      border-radius: $radius-medium;
      padding: map-get($spacers, '1') map-get($spacers, '1')
        map-get($spacers, '1') 0;
      margin-left: map-get($spacers, '2');
    }
    input {
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
    }
    .form-input {
      flex-grow: 1;
    }
    .input {
      border: none;
      background-color: $color-gray-1;
    }
    .QMuiButton {
      &.isPrimary {
        box-shadow: none;
      }
    }
    .chat-form-avatar {
      width: $avatar-size;
      height: $avatar-size;
      min-width: $avatar-size;
      min-height: $avatar-size;
    }
    svg {
      &.chat-form-avatar {
        color: $color-gray-1;
      }
    }
    div {
      &.chat-form-avatar {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: math.div($avatar-size, 2);
      }
    }
  }
}

.chat-form-submit {
  white-space: nowrap;
}

@include respond-to('small') {
  .chat {
    width: 100%;
    padding: map-get($spacers, '3');
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .chat-wrapper {
    flex-grow: 1;
    padding: map-get($spacers, '3');
  }

  .chat-inner {
    margin-bottom: 0;
    margin-top: 1rem;
    padding-bottom: 7rem;
  }

  .chat-discussion,
  .chat-collaboration {
    .chat-message {
      padding: 1rem 1rem 1rem 3.5rem;
    }

    .chat-message-me {
      padding: 1rem 3.5rem 1rem 1rem;
    }
  }

  .chat-collaboration {
    .chat-title {
      display: none;
    }
  }

  .chat-form-submit {
    padding: 0.5rem 1rem;
    min-width: auto;

    .MuiButton-label {
      svg {
        margin: 0 !important;
      }

      & > span:first-child {
        display: none;
      }
    }
  }
}

.QMuiModal-paper {
  border: 2px solid $color-alpha;
  border-radius: 10px;
  width: 500px;
  max-width: 100%;
}

.QMuiModal-title h2 {
  font-family: inherit;
  color: $color-alpha;
  font-size: 2rem;
}

.Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  height: calc(var(--lay-header-height) - 1rem);
  background: linear-gradient(to right, #0378a0, #0a90be);
}

.Header-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  padding-left: 1rem;
  height: var(--lay-header-height);
  z-index: 4;
}

.Header-wave {
  display: block;
  position: absolute;
  width: calc(100% + 2px);
  max-width: none;
  left: 0;
  bottom: -1rem;
  z-index: 4;
  color: $color-blue-2;
}

.Header-logo {
  display: block;
  height: 78%;
  position: relative;
  z-index: 10;
  padding-bottom: 10px;

  & > img {
    height: 100%;
  }
}

.Header-menuBtn.burger-button {
  margin-left: 1rem;

  &:not(.is-active) i {
    background-image: linear-gradient(white, white);
  }

  i {
    &::before,
    &::after {
      background: white;
    }
  }
}

@include respond-to('large-up') {
  .Header-menuBtn.burger-button {
    display: none;
  }
}

.infinite-scroll {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    height: auto;
  }
  .swiper-wrapper {
    height: 100%;
  }
  &-loading {
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: map-get($spacers, '5');
    svg {
      -webkit-animation: spin 1s linear infinite;
      -moz-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
      color: $color-alpha;
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

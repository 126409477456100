.DomainManager-domain {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: -1.2px;
  color: $color-alpha-light;
  width: 100%;
  border: 0;
  padding: 0;
}

.DomainManager-subDomain {
  font-family: inherit;
  border: 0;
  padding: 0;
  color: $color-alpha-light;
  font-weight: bold;
  width: 100%;
  font-size: 1.5rem;
}

.DomainManager-domainInput {
  border-radius: 20px !important;
  border: 1px solid color('gray');
  width: 100%;
  padding-left: 1rem;
  font-size: 1.2rem;

  & > input {
    padding-top: 12px;
  }
}

.DomainManager-inputBtn {
  background-color: transparent !important;

  svg {
    fill: $color-alpha;
  }
}

.DomainManager-deleteBtn.QMuiButton {
  border: 0;
  background: none;
  height: 100%;
  cursor: pointer;
  padding: 0;
}

.QMuiDataGrid {
  display: flex;
  flex-direction: column;
  position: relative;
}

.QMuiDataGrid-noResults {
  color: $color-alpha-light;
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  height: 6.5rem;
  background: white;
  top: 7.5rem;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  font-size: 1.5rem;
}

// Override Material styles
.MuiDataGrid-root {
  border: 0;

  .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-cell {
    border: 0;
  }

  .MuiDataGrid-overlay {
    z-index: 2;
  }
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }
}

.MuiDataGrid-columnsContainer {
  border-bottom: 0 !important;
}

.MuiDataGrid-columnHeaderTitle {
  color: $color-alpha;
  font-size: 1.5rem;
}

.MuiDataGrid-viewport .rendering-zone {
  border-radius: 20px;
}

.MuiDataGrid-window {
  box-shadow: $shadow;
  background-color: white;
  border-radius: 20px;
}

.MuiDataGrid-dataContainer {
  color: $color-alpha;
  font-size: 1.3rem;

  .MuiDataGrid-row {
    &:first-child {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      .MuiDataGrid-cell {
        &:first-child {
          border-top-left-radius: 20px;
        }

        &:last-child {
          border-top-right-radius: 20px;
        }
      }
    }

    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;

      .MuiDataGrid-cell {
        border: 0;

        &:first-child {
          border-bottom-left-radius: 20px;
        }

        &:last-child {
          border-bottom-right-radius: 20px;
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
}

.MuiPaginationItem-root {
  font-size: 1.2rem;
}

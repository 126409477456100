.ExpertHome-404 {
  margin-top: 40rem;
  text-align: center;

  h1 {
    color: $color-alpha;
    font-size: 3rem;
  }

  p {
    font-size: 1.8rem;
  }
}

@include respond-to('small') {
  .ExpertHome {
    margin-bottom: 9rem;
  }
}

.notes {
  width: 48%;
  &-header {
    margin-bottom: 1rem;
  }
  &-title {
    color: $color-alpha;
    margin-bottom: 0;
  }
  &-form {
    background-color: $color-gray-1;
    border-radius: $radius-large;
    padding: map-get($spacers, '4');
    margin-bottom: map-get($spacers, '5');
    box-shadow: $shadow;
    .input {
      border: none;
      box-shadow: $shadow;
    }
    .QSelect__control {
      border: none;
      box-shadow: $shadow;
    }
    .QMuiTextarea {
      margin-bottom: 20px;
      textarea {
        background-color: $white;
        border: none;
        box-shadow: $shadow;
      }
    }
  }
  &-inner {
    max-height: 420px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      width: 1rem;
      background-color: $white;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-gray-1;
      border-radius: 0.5rem;
    }
  }
  &-item {
    position: relative;
    background-color: $color-gray-1;
    border: 1px solid $bgMain;
    border-radius: $radius-large;
    padding: map-get($spacers, '4');
    margin-bottom: map-get($spacers, '5');
    box-shadow: $shadow;
    &-header {
      margin-bottom: map-get($spacers, '4');
      padding-right: 6.6rem;
    }
    &-title {
      color: $color-alpha;
    }
    &-category {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $color-alpha;
      font-weight: 700;
      &-badge {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 0.75rem;
        margin-right: map-get($spacers, '3');
      }
    }
    &-date {
      font-weight: 700;
      display: flex;
      justify-content: flex-end;
    }
    &-actions {
      position: absolute;
      top: map-get($spacers, '1');
      right: map-get($spacers, '1');
    }
  }
}

@media screen and (max-width: 1000px) {
  .notes {
    width: 100%;
  }
}

@include respond-to('small') {
  .notes-inner {
    max-height: calc(100% - 7rem);
  }
}
@include respond-to('small-up') {
  .notes-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: map-get($spacers, '5');
  }
}

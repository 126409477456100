.QMsg {
  display: block;
  font-weight: bold;
  font-size: 1.4rem;

  &.isPrimary {
    color: $color-alpha;
  }

  &.isSecondary {
    color: $color-beta;
  }

  &.isDanger {
    color: $color-gamma;
  }
}

.SimplyEditor {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 12%;
}

.SimplyEditor-form {
  width: 100%;
}

.SimplyEditor-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.SimplyEditor-editor {
  margin: 0 0 20px;
  width: 100%;
}

.SimplyEditor-title {
  width: 400px;
  margin-bottom: 3rem;
}

.SimplyEditor-label {
  font-size: 1.6rem;
  font-weight: 500;
  color: $color-alpha-light;
  margin-bottom: 10px;
  padding-left: 5px;
  display: block;
}

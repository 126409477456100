.page-container {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  margin-top: 8rem;

  &.full-height {
    height: calc(100vh - 12rem);
  }

  @include respond-to('small-up') {
    margin-top: 13rem;

    &.full-height {
      height: calc(100vh - 15rem);
    }
  }

  @include respond-to('medium-up') {
    margin-right: 3rem;
    margin-left: 3rem;
  }
}

.page-thin-container {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 5rem;
  padding: 0 1.5rem;

  @include respond-to('medium-up') {
    padding: 0 3rem;
  }

  @include respond-to('small-up') {
    .page-title {
      margin-bottom: 6rem;
    }
  }
}

.page-title {
  color: $color-beta;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;

  @include respond-to('small-up') {
    margin-bottom: 3rem;
  }
}

.page-section {
  margin: 3rem 0;

  h2 {
    color: $color-alpha;
    font-weight: bold;
    margin: 1rem;
  }
}

$main-menu-width: 12rem;

.main-wrapper {
  flex-grow: 1;
}

@include respond-to('small') {
  .main-wrapper {
    padding: 0;
  }
}

@include respond-to('small-up') {
  .main-wrapper {
    padding-left: calc(#{$main-menu-width});
  }
}

.discussions {
  &-wrapper {
    position: relative;
    height: calc(100vh - 4rem);
    margin: 0;
    margin-top: 4rem;
    @include respond-to('small') {
      height: 100vh;
      margin: 0;
    }
  }
  &-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 35%;
    display: flex;
    flex-direction: column;
    @include respond-to('small') {
      position: fixed;
      width: 100%;
    }
  }
  &-form {
    height: 5rem;
    background-color: $color-gray-1;
    padding: 8rem map-get($spacers, '6');
    text-align: center;
    @include respond-to('small') {
      padding-bottom: 7rem;
    }
  }
  &-list {
    flex-grow: 1;
    position: relative;
    border-right: 1px solid $color-gray-1;
  }
  &-empty {
    padding: map-get($spacers, '6');
    display: flex;
    justify-content: center;
  }
  &-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: map-get($spacers, '4') map-get($spacers, '3');
    border-top: 1px solid $color-gray-1;
    cursor: pointer;
    &.discussions-item-current,
    &:hover {
      background-color: $color-gray-1;
      svg {
        color: $white;
      }
    }
    &-avatar {
      flex-basis: 18%;
      margin-top: -0.5rem;
      margin-right: 1rem;
      svg {
        width: $avatar-size;
        height: $avatar-size;
        color: $color-gray-1;
      }
      img {
        width: $avatar-size;
        height: $avatar-size;
      }
    }
    &-content {
      flex-basis: 70%;
    }
    &-title {
      color: $color-alpha;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-message {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $color-gray-3;
    }
    &-date {
      white-space: nowrap;
      flex-basis: 15%;
      font-size: 1.125rem;
      font-weight: bold;
      padding: 0.25rem;
      text-align: right;
      color: $color-gray-3;
    }
    .badge {
      background-color: $color-beta;
      position: absolute;
      right: 1rem;
      bottom: 1rem;
    }
    &.discussions-item-current .badge {
      display: none;
    }
    &.discussions-item-hasUnseenMsgs {
      .discussions-item-title {
        font-weight: 700;
      }
    }
  }
  &-content {
    position: absolute;
    left: 37%;
    width: 61%;
    top: 10rem;
    @include respond-to('small-up') {
      height: calc(100vh - 14rem);
    }
    &-back {
      display: none;
      &-history {
        color: $color-alpha;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0.35rem;
      }
    }
    .chat {
      width: 100%;
      @include respond-to('small-up') {
        display: flex;
        flex-direction: column;
        height: calc(100% - 2rem);
      }
      &-title {
        padding-left: 4.5rem;
      }
    }
    @include respond-to('small') {
      display: none;
      position: fixed;
      left: 0;
      width: 100%;
      bottom: 0;
      top: 0;
      background-color: $white;
      z-index: 9999;
      &-back {
        position: fixed;
        top: map-get($spacers, '4');
        left: map-get($spacers, '2');
        display: flex;
        z-index: 10001;
        color: $white;
        &-history {
          display: none;
        }
      }

      &-visible {
        display: block;
      }
      .chat {
        padding: 0;
        &-title {
          background-color: $color-alpha;
          color: $white;
          margin-bottom: 0;
          padding-left: 5rem;
          padding-right: map-get($spacers, 2);
          display: block;
          line-height: 6rem;
          height: 6rem;
          font-size: 1.5rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &-wrapper {
          box-shadow: none;
        }
        &-inner {
          padding-bottom: 0;
          margin-bottom: 8rem;
        }
        &-form {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 10000;
          padding: map-get($spacers, '2');
          background-color: $white;
          height: 7rem;
          form {
            margin-left: 0;
          }
          &-avatar {
            display: none;
          }
        }
      }
      .swiper-slide {
        padding: 0 1rem;
      }
    }
  }
}

.DocumentTags {
  list-style: none;
  padding: 0;
  margin: 2rem;
  display: flex;
  align-items: stretch;

  li:not(:last-child) {
    margin-right: 1rem;
  }
}

.DocumentTags-tag {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  height: 100%;
  padding: 1rem 0.5rem;
  width: 10rem;

  font-weight: bold;
  color: $color-alpha;
  font-size: 1.3rem;
  box-shadow: $shadow;
  cursor: pointer;

  &.isSelected {
    background: $color-alpha;
    color: white;

    svg {
      color: white;
    }
  }

  svg {
    height: 2.5rem;
    margin-bottom: 1rem;
    margin-right: -1rem;
  }
}

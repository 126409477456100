.AdminMenu {
  background: $color-gray-1;
  box-shadow: $shadow;
  position: sticky;
  width: 0;
  z-index: 10;
  height: 100%;
  transition: 400ms transform ease;
}

.AdminMenu-static,
.AdminMenu-top {
  min-height: 100%;
  width: 100%;
}

.AdminMenu-static {
  position: fixed;
  z-index: 40;
  top: 0;
  height: 100vh;
  min-height: 100%;
  width: 100%;
  transition: 0.3s width ease;
  padding-top: calc(1rem + var(--lay-header-height));
  border-right: 1px solid lightgray;
  background: $color-gray-1;

  &.isSubMenuOpen {
    width: $lay-admin-menu-width * 2;
  }
}

.AdminMenu-top {
  border-right: 1px solid lightgray;
  position: relative;
  display: flex;
  min-height: 100%;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.AdminMenu-bottom {
  margin-top: auto;
  margin-bottom: 3rem;
}

.AdminMenu-title {
  display: flex;
  align-items: center;
  font-size: 2rem;
  height: 3rem;
  font-weight: 600;
  color: $color-alpha-light;
  padding: 0 1rem;
}

.AdminMenu-item {
  text-decoration: none;
}

@include respond-to('small') {
  .AdminMenu:not(.isOpen) {
    transform: translateX(-100vw);
  }

  .AdminMenu-static,
  .AdminMenu-top {
    width: 100vw;
  }
}

@include respond-to('small-up') {
  .AdminMenu:not(.isOpen) {
    transform: translateX(-#{$lay-admin-menu-width});
  }

  .AdminMenu-static,
  .AdminMenu-top {
    width: $lay-admin-menu-width;
  }
}

@include respond-to('large-up') {
  .AdminMenu {
    z-index: 2;
    transform: none;
    transition: 0.3s width ease;
    width: $lay-admin-menu-width;
    min-width: $lay-admin-menu-width;
  }

  .AdminMenu:not(.isOpen) {
    transform: none;
  }
}

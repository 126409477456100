.QMuiSwitch {
  .Mui-checked .MuiIconButton-label {
    color: $color-alpha-light;
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: $color-alpha-light;
  }

  .Mui-checked:hover {
    background-color: transparentize($color-alpha-light, 0.9);
  }
}

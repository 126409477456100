.Breadcrumb {
  display: flex;
  font-size: 1.5rem;
  margin-bottom: 3rem;
  position: absolute;
  top: 10rem;
  left: 0;
  list-style: none;

  li {
    position: relative;
    width: 12rem;
    text-align: center;
    --scale: 1;

    &:not(:first-child)::before {
      content: '';
      position: absolute;
      bottom: -1.2rem;
      left: 0;
      width: 12rem;
      height: 0.2rem;
      transform: translateX(-50%);
      background: #ccc;
    }

    &:last-child .Breadcrumb-item::after {
      background: $color-alpha-light;
    }
  }
}

.Breadcrumb-item {
  text-decoration: none;
  color: $color-alpha-light;
  width: 12rem;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &::after {
    content: '';
    position: absolute;
    bottom: -1.6rem;
    left: 50%;
    transform: translateX(-50%) scale(var(--scale));
    width: 1rem;
    height: 1rem;
    background: #ccc;
    border-radius: 50%;
  }
}
